import React from 'react'

import styled from 'styled-components'

const Text = styled.p`
    line-height: 140%;
`

const Title = styled.h3`
    line-height: 140%;
`
export default () => {
    return (
        <>
            <Text>
                This Privacy Policy describes the policies and procedures of BBP
                Hosting, in association with - LiquidNet US LLC, a US company
                headquartered in Fort Lauderdale, Florida, and LiquidNet Ltd., a
                UK company headquartered in London, UK, on the collection, use
                and disclosure of your information. Legal details about the two
                companies can be found in the 'Contact Us' section of this
                document.
            </Text>
            <Text>
                For the sake of brevity, BBP Hosting, LiquidNet US LLC and
                LiquidNet Ltd. will be referred to as 'We', 'Us', 'Our' and
                'Service Provider' in this document.
            </Text>
            <Text>
                This Privacy Policy does not apply to information our customers
                may process when using our services.
            </Text>
            <Text>
                Privacy and security are of utmost importance to the Service
                Provider and we strive to ensure that our technical and
                organizational measures in place respect your data protection
                rights.
            </Text>
            <Title>COLLECTION OF PERSONAL DATA</Title>
            <Text>
                "Personal data" refers to any information relating to an
                identifiable individual or his or her personal identity.
                Personal data is collected on our website by LiquidNet US LLC.,
                a US company headquartered in Fort Lauderdale, Florida. The
                personally identifiable information that LiquidNet US LLC may
                collect from you when registering for an account includes: first
                and last name, postal address, phone number, email and IP
                address.
            </Text>
            <Text>
                Your registrant information will be needed for the following
                purposes:
            </Text>
            {`provision of our services to you;
  prevention of fraudulent transactions;
  billing/invoicing operations;
  provision of a sales/technical support service;
  maintenance of your account with us;
  dispute resolution cases;
  enforcement of our Terms of Service, or other legal rights, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency;
  collection of aggregate statistics about use of our services;
  Much of the data collected is aggregated or statistical data about how individuals use our Services, and is not linked to any personal data, but to the extent it is itself personal data, or is linked or linkable to personal data, we treat it accordingly.`
                .split('\n')
                .map((t, i) => (
                    <Text key={i}>{t}</Text>
                ))}
            <Text>
                The Service Provider will NOT send you any unsolicited
                information, including emails, except as mentioned hereby.
            </Text>
            <Title>COMPLIANCE WITH LAWS AND LAW ENFORCEMENT</Title>
            <Text>
                The Service Provider cooperates with government and law
                enforcement officials to enforce and comply with the law. We
                will disclose any information about Users upon valid request by
                government or law officials as we, in our sole discretion,
                believe necessary or appropriate to respond to claims and legal
                process (including without limitation subpoenas), to protect
                your property and rights, or the property and rights of a third
                party, to protect the safety of the public or any person, or
                stop activity we consider illegal or unethical.
            </Text>
            <Title>CHANGES TO PRIVACY POLICY</Title>
            <Text>
                Any changes to our Privacy Policy will be placed here and will
                supersede this version of our Policy. We will take reasonable
                steps to draw your attention to any changes in our Policy.
                However, to be on the safe side, we suggest that you read this
                document each time you use the website to ensure that it still
                meets with your approval.
            </Text>
            <Title>CONTACT US</Title>
            <Text>
                If you have any questions about our Privacy Policy, or if you
                want to know what information we have collected about you,
                please use our Contact Us form.
            </Text>
        </>
    )
}
