import React from 'react'

import Layout from '../components/layout'

import SEO from '../components/seo'

import PrivacyPolicy from '../components/privacy-policy'

import Footer from '../components/footer'

import Header from '../components/header'

import styled from 'styled-components'

import { Container } from '../components/styles'

const Title = styled.h1`
    margin-bottom: 1rem;
`

export default () => {
    return (
        <Layout>
            <Header mode="site-header" />
            <SEO
                title={'Privacy policy'}
                description={`Verifeco is a technology company that builds software
                        products for the world. Businesses of every size—from
                        new startups to public companies—use our software to
                        exchange and manage their business card online. Since
                        being in beta the idea have changed how many corporates
                        and professionals think, use and issue business cards.`}
            />
            <Container>
                <Title>Privacy policy</Title>
                <PrivacyPolicy />
            </Container>
            <Footer />
        </Layout>
    )
}
